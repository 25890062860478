var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalEditarContacto",attrs:{"titulo":"Editar contacto del proveedor","cargado-modal":_vm.cargando,"icon":"entrega","tamano":"modal-lg","no-aceptar":"","adicional":"Editar"},on:{"adicional":_vm.editarContacto}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"rules":"required|max:40","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre del contacto"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v("Cargo")]),_c('ValidationProvider',{attrs:{"rules":"required|max:40","name":"cargo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Cargo del contacto"},model:{value:(_vm.model.cargo),callback:function ($$v) {_vm.$set(_vm.model, "cargo", $$v)},expression:"model.cargo"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6 mt-4"},[_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v("Número de teléfono")]),_c('ValidationProvider',{attrs:{"rules":"required|max:25","name":"número de télefono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Número de télefono"},model:{value:(_vm.model.telefono),callback:function ($$v) {_vm.$set(_vm.model, "telefono", $$v)},expression:"model.telefono"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6 mt-4"},[_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v("Correo eléctronico")]),_c('ValidationProvider',{attrs:{"rules":"max:60|email","name":"correo eléctronico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Correo eléctronico"},model:{value:(_vm.model.correo),callback:function ($$v) {_vm.$set(_vm.model, "correo", $$v)},expression:"model.correo"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 mt-4"},[_c('p',{staticClass:"f-14 text-general pl-3"},[_vm._v(" Observaciones ")]),_c('ValidationProvider',{attrs:{"rules":"max:500","name":"observaciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","rows":4,"placeholder":"Comentario del proveedor"},model:{value:(_vm.model.observaciones),callback:function ($$v) {_vm.$set(_vm.model, "observaciones", $$v)},expression:"model.observaciones"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"col-auto"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }